/* eslint-disable react/prop-types */
/* eslint-disable @next/next/no-img-element */

import { useEffect, useState, FunctionComponent } from "react";
import Head from "next/head";

interface Prop {
	item: any;
}

// eslint-disable-next-line react/prop-types
const HtmlBlock: FunctionComponent<Prop> = ({ item }) => {
	const [html, setHtml] = useState<string>(null);
	const [css, setCss] = useState<any>(null);

	useEffect(() => {
		// eslint-disable-next-line react/prop-types
		setHtml(item.html);
		// eslint-disable-next-line react/prop-types
		setCss(item.css);
	}, [html, item]);

	return (
		html && (
			<>
				{css && (
					<Head>
						<style type="text/css">{css}</style>
					</Head>
				)}

				<div
					className="col-md-12 p-0"
					dangerouslySetInnerHTML={{ __html: html }}
				/>
			</>
		)
	);
};

export default HtmlBlock;
