/**
 * Use `segmentAddCart()` to feed segment event
 * @param {string} page - page was trigger
 * @param {string} pageParent - page was trigger
 * @param {string} sku - SKU of Item
 * @param {string} qty - Qty of Item
 * @param {string} program - where was trigger`
 * @param {string} productId - id of the product`
 */

 interface segmentAddCartProps {
	event: string;
	page: string;
	pageParent: string;
	sku: string;
	qty: number;
	program: string;
	productId: string;
	variantId?: string;
}

export const segmentEvent = ({
	event,
	page,
	pageParent,
	sku,
	qty,
	program,
	productId,
	variantId = null,
}: segmentAddCartProps): void => {
	if (typeof window === "undefined") return;

	window["analytics"].track(event, {
		page,
		pageParent,
		sku,
		qty,
		program,
		productId,
		variantId,
	});
};