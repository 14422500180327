import { useState, useMemo } from "react";
import styles from "./RenderSelect.module.scss";
import { segmentEvent } from "lib/segment";
import modalStyles from "@/components/DealModal/DealModal.module.scss";
import HtmlBlock from "@/components/HtmlBlock/HtmlBlock";
import SpandArrow from "public/expand-button.svg";
import { Fragment } from "react";
import { IProduct } from "interfaces/products.interfaces";
import { IPvariant } from "interfaces/products.interfaces";
import Modal from "react-bootstrap/Modal";

export interface RenderSelectProps {
	product: IProduct;
	setSelectVariant: Function;
	selectVariant: IPvariant;
	setVariantId?: Function;
	areMultiVariants?: boolean;
	checkRedereing?: Function;
	selectSwatchUrl?: string;
	productContent?: any[];
	modalContent?: any;
	selectId?: string;
	loading?: boolean;
}

const RenderSelect = ({
	product,
	setSelectVariant,
	setVariantId = null,
	selectVariant,
	selectSwatchUrl = null,
	areMultiVariants = false,
	productContent,
	modalContent,
	selectId = null,
	loading = false,
}: RenderSelectProps): JSX.Element => {
	const [imgTypeVariant, setImgTypeVariant] = useState(null);
	const [showModal, setShowModal] = useState(false);

	useMemo(() => {
		setImgTypeVariant(selectSwatchUrl);
	}, [selectSwatchUrl]);

	const selectHandleChange = ({ target }) => {
		const { value } = target;
		if (areMultiVariants) {
			let variant = product?.variants.find(
				(variant) => variant?.id === value.toString()
			);
			setVariantId(variant);
			return null;
		}

		let variantPick = product?.variants.find(
			(variant) => variant?.id === value
		);

		setSelectVariant(variantPick);

		variantPick &&
			segmentEvent({
				event: "variantChosen",
				page: "productPage",
				pageParent: "product",
				sku: variantPick.sku,
				qty: 1,
				program: null,
				productId: product.id,
				variantId: variantPick.id,
			});
	};

	const selectHandleBlur = ({ target }) => {
		const { value } = target;

		if (areMultiVariants) {
			let variant = product?.variants.find(
				(variant) => variant?.id === value.toString()
			);
			setVariantId(variant);
			return null;
		}

		let variantPick = product?.variants.find(
			(variant) => variant?.id === value
		);

		setSelectVariant(variantPick);
	};

	const handleClose = () => {
		setShowModal(false);
	};

	const openModal = () => {
		setShowModal(true);
	};

	return (
		<>
			{modalContent && (
				<Modal
					animation={false}
					size={"lg"}
					dialogClassName={styles.customModal}
					show={showModal}
					onHide={() => handleClose()}
					centered
					className={styles.modal}
				>
					<Modal.Header closeButton className={modalStyles.header}>
						<Modal.Title className={modalStyles.title}>
							{modalContent?.modalLabel}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className={modalStyles.modalBody}>
						{modalContent && <HtmlBlock item={modalContent} />}
					</Modal.Body>
				</Modal>
			)}

			{product && product?.variants.length > 1 && (
				<>
					{loading ? (
						<div className={styles.loadingSelectPanel}>
							<div className={styles.loadingLabel}></div>
							<div className={styles.loadingSelect}></div>
						</div>
					) : (
						<>
							<div className={styles.row}>
								<label
									htmlFor="mySelect"
									className={styles.label}
								>
									{
										product?.variants[0].selectedOptions[0]
											.name
									}
								</label>
								{product?.variants[0].selectedOptions[0].name ==
									"Size" &&
									modalContent && (
										<div
											role="button"
											onClick={openModal}
											onKeyPress={openModal}
											tabIndex={0}
											className={styles.modalSelector}
										>
											{modalContent?.modalLabel}
										</div>
									)}
							</div>

							<section className={`${styles.varianst}`}>
								<div className={styles.flavorOption}>
									<img
										src={
											imgTypeVariant || "/unflavored.png"
										}
										alt="variant"
									/>
								</div>
								<div className={styles.flavorSpand}>
									<SpandArrow />
								</div>
								<select
									id={selectId}
									onChange={selectHandleChange}
									onBlur={selectHandleBlur}
									value={selectVariant?.id}
								>
									{product?.variants?.length > 1 && (
										<option selected={!selectVariant?.id}>
											{`Pick your ${product?.variants[0]?.selectedOptions[0]?.name.toLowerCase()}`}
										</option>
									)}
									{!productContent ? (
										product?.variants &&
										product?.variants.map(
											(variant: IPvariant) => (
												<Fragment key={variant?.id}>
													{variant.availableForSale ==
													true ? (
														<option
															value={variant?.id}
														>
															{variant?.title}
														</option>
													) : (
														<>
															{variant.content
																?.storeExclusive ? (
																<>
																	<option
																		value={
																			variant?.id
																		}
																	>
																		{
																			variant?.title
																		}
																		{
																			" - Store Exclusive"
																		}
																	</option>
																</>
															) : (
																<>
																	<option
																		value={
																			variant?.id
																		}
																	>
																		{
																			variant?.title
																		}
																		{
																			" [Email Me When Back In Stock]"
																		}
																	</option>
																</>
															)}
														</>
													)}
												</Fragment>
											)
										)
									) : (
										<>
											{product?.variants &&
												product?.variants.map(
													(variant, i) => (
														<Fragment
															key={variant?.id}
														>
															{variant.availableForSale ==
																true &&
																productContent[
																	i
																]?.fields
																	?.storeExclusive ==
																	null && (
																	<option
																		value={
																			variant?.id
																		}
																	>
																		{
																			variant?.title
																		}
																	</option>
																)}
															{variant.availableForSale ==
															false ? (
																!productContent[
																	i
																]?.fields
																	?.storeExclusive ? (
																	<option
																		value={
																			variant?.id
																		}
																	>
																		{`${variant?.title} [Email Me When Back In Stock]`}
																	</option>
																) : (
																	productContent[
																		i
																	]?.fields
																		?.storeExclusive !==
																		null && (
																		<option
																			value={
																				variant?.id
																			}
																		>
																			{`${variant?.title} - Store Exclusive`}
																		</option>
																	)
																)
															) : null}
														</Fragment>
													)
												)}
										</>
									)}
								</select>
							</section>
						</>
					)}
				</>
			)}
		</>
	);
};

export default RenderSelect;
