import { IPvariant } from "interfaces/products.interfaces";
const minPrice = (variants: IPvariant[]) => {
	if (variants) {
		let price = null;
		let multiplePrices = false;
		for (let variant of variants) {
			if (price !== null && price !== variant.price) {
				multiplePrices = true;
				break;
			}
			price = variant.price;
		}

		if (!multiplePrices) {
			return variants[0];
		}

		const minPric = variants.reduce((min, max) =>
			min.price < max.price ? min : max
		);
		return minPric;
	}

	return null;
};

export default minPrice;
