/**
 *  This function is used to get the URL to the corresponding swatch
 *  for selected variant.
 *
 * @param {Object} selectVariant pass the select variant
 * @param {Object} swatches pass the global array of swatches
 * @returns {swatchUrl: String}
 */

const setSwatch = (selectVariant, swatches) => {
	const swatchUrl = swatches.filter((el) => {
		if (selectVariant?.title) {
			return el?.fields?.swatchName === selectVariant?.title;
		}
		return el?.fields?.swatchName === selectVariant.value;
	});
	return swatchUrl[0]?.fields?.swatchImage?.fields?.file?.url;
};

export default setSwatch;
